<template>
  <section class="container">
    <div class="confirm-favorite margin__md">
      <div class="tile-heading">
        <h2>Love This Order?</h2>
        <hr />
      </div>
      <p class="mb-15">
        Mark it as a favorite to save time in the future.
        Once marked as a favorite, the products, product customizations, and final details can be pre-filled in future orders.
        <br />
        <i>The first four ranked favorites will appear on your home page.</i>
      </p>
      <section class="confirm-favorite--tiles">
        <SetFav :active="active" />
        <FavDetails :active="active" />
      </section>
    </div>
  </section>
</template>

<script>
import SetFav from "./SetFav";
import FavDetails from "./FavDetails";
export default {
  props: ["active"],
  components: {
    SetFav,
    FavDetails
  }
};
</script>

<style scoped lang="scss">
.confirm-favorite--tiles {
  @include grid($cols: 1fr, $row-gap: 50px, $col-gap: 50px);
}
@media (min-width: $md) {
  .confirm-favorite--tiles {
    grid-template-columns: 1fr 1fr;
  }
}
</style>