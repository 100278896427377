<template>
  <section class="tile">
    <h3>Set as Favorite</h3>
    <p>Marking or unmarking this order as a favorite can be undone at any time. You are not required to enter favorite details in the next tile.</p>
    <p
      class="text__bold text__center"
    >This item is currently marked as {{ active.isFavorite ? "Favorite" : "Not Favorite" }}</p>
    <div class="favorite-buttons">
      <button class="btn btn__frost flex__center" @click="toggleIsFavorite">
        <span class="text__white">{{ active.isFavorite ? "Forget this One" : "Favorite It!" }}</span>
        <div class="icon-container flex__center pointer ml-10">
          <font-awesome-icon :icon="heartIcon" size="2x" class="icon" />
        </div>
      </button>
      <router-link
        v-if="active.isFavorite"
        :to="`/new-order/order-details?id=${active._id}`"
        class="btn btn__green"
      >
        New Order
        <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
      </router-link>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["active"],
  data() {
    return {
      form: {
        _id: this.active ? this.active._id : null,
        isFavorite: this.active ? this.active.isFavorite : false
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateOrder"]),
    async toggleIsFavorite() {
      this.form.isFavorite = !this.form.isFavorite;
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        await this.startUpdateOrder(this.form);
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.form.isFavorite = !this.form.isFavorite;
        this.startSetIsSubmitting({ bool: false });
      }
    }
  },
  computed: {
    heartIcon() {
      return this.active.isFavorite ? "heart-broken" : "heart";
    }
  }
};
</script>

<style scoped lang="scss">
.favorite-buttons {
  margin-top: 15px;
  @include grid($cols: 1fr, $row-gap: 15px, $col-gap: 25px);
}
.icon-container {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: #fff;
}
.icon {
  margin-top: 4px;
  color: $green;
  font-size: 1.85rem;
  transition: all 0.15s ease-in-out;
}
.btn:hover .icon {
  color: $maroon;
  animation: 0.5s ease-in-out infinite pulse;
}
@keyframes pulse {
  0% {
    font-size: 1.85rem;
  }
  50% {
    font-size: 2.25rem;
  }
  100% {
    font-size: 1.85rem;
  }
}
@media (min-width: $lg) {
  .favorite-buttons {
    grid-template-columns: 1.5fr 1fr;
  }
}
</style>