<template>
  <section class="tile">
    <div class="flex__between">
      <h3>Favorite Details</h3>
      <button class="btn btn__sm btn__red" @click="toggleEditing">Edit</button>
    </div>
    <div class="confirm-favorite--form">
      <p>You may edit the name or rank at any time, even if this is not a favorite order at the time.</p>
      <label>
        <p>
          <span class="text__bold mr-10">Name</span>
          <span v-if="editing" class="text__sm text__light">(max 20 characters)</span>
        </p>
        <span v-if="!editing">{{ active.favoriteName ? active.favoriteName : "none" }}</span>
        <input
          v-else
          type="text"
          v-model="form.favoriteName"
          placeholder="name of favorite"
          maxlength="20"
        />
      </label>
      <label>
        Rank
        <span v-if="!editing">{{ active.favoriteRank ? active.favoriteRank : "unranked" }}</span>
        <select v-else v-model="form.favoriteRank">
          <option value></option>
          <option v-for="num in 15" :key="num" :value="num">{{num}}</option>
        </select>
      </label>
      <div v-if="editing" class="flex__between">
        <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
        <button class="btn btn__sm btn__green" @click="saveFavorite">Done</button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["active"],
  data() {
    return {
      editing: false,
      isValidated: true,
      form: {
        _id: this.active ? this.active._id : null,
        favoriteName:
          this.active && this.active.favoriteName
            ? this.active.favoriteName
            : null,
        favoriteRank:
          this.active && this.active.favoriteRank
            ? this.active.favoriteRank
            : 1000
      }
    };
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateOrder"]),
    async saveFavorite() {
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        await this.startUpdateOrder(this.form);
        this.toggleEditing();
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    toggleEditing() {
      this.editing = !this.editing;
    }
  }
};
</script>

<style scoped lang="scss">
.confirm-favorite--form {
  @include grid($cols: 1fr, $row-gap: 5px);
  label {
    @include grid($cols: 1fr, $row-gap: 3px);
    font-weight: bold;
    > span {
      font-weight: normal;
    }
  }
}
</style>